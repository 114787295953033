<template>
    <main>              
        <loading :active="isLoading"
                 :can-cancel="false"
                 :on-cancel="onCancel"                 
                 :is-full-page="fullPage"/>    
        <HeadShop />

        <section class="section pt-0 my-status">
            <div class="container">
                <p class="breadcrumb"><a href="/"><span class="icon-home-outline"></span></a> / <span>Registro usuario</span></p>
                <h2><a href="#" @click="router.go(-1)"><img src="/images/arrowleft.svg" alt="Club de Sastreria Barrington"></a> Registro usuario</h2>
                
                <!-- jkjjkjkjjkjkjjkjk -->
                <form action="" class="form container container--xs" @submit.prevent="onSubmit" v-if="!showSuccessRegisterUser">
                    <input type="hidden" v-model="formRegisterUser.username">
                    <div class="form__control">
                        <label class="form__label">Seleccionar tipo de usuario :</label>
                        <select  class="form__field form__field--select" v-model="formRegisterUser.type_user">
                            <option :value="null">Seleccione un tipo de usuario</option>
                            <option :value="tu.id" v-for="(tu, k) in typeUser" :key="k">{{ tu.name }}</option>
                        </select>
                    </div>
                    <p class="form__alert" v-if="messageError.errors.type_user">
                            {{ messageError.errors.type_user[0] }}
                    </p>
                    
                    <div class="form__control">
                        <label class="form__label">Nombres :</label>
                        <input type="text" class="form__field" v-model="formRegisterUser.first_name" placeholder="Nombres completos del usuario">
                    </div>
                    <p class="form__alert" v-if="messageError.errors.first_name">
                            {{ messageError.errors.first_name[0] }}
                    </p>
                    <div class="form__control">
                        <label class="form__label">Apellidos :</label>
                        <input type="text" class="form__field" v-model="formRegisterUser.last_name" placeholder="Apellidos completos del usuario" >
                    </div>
                    <p class="form__alert" v-if="messageError.errors.last_name">
                            {{ messageError.errors.last_name[0] }}
                    </p>
                    <div class="form__control">
                        <label class="form__label">DNI :</label>
                        <input type="text" class="form__field" v-model="formRegisterUser.document_code" placeholder="" >
                    </div>
                    <p class="form__alert" v-if="messageError.errors.document_code">
                            {{ messageError.errors.document_code[0] }}
                    </p> 

                    <div class="form__control">
                        <label class="form__label form__label--error">Email :</label>
                        <input type="email" class="form__field" v-model="formRegisterUser.email" placeholder="usuarioEmail@email.com">
                        <p class="form__alert" v-if="messageError.errors.email">
                            {{ messageError.errors.email[0] }}
                        </p>
                            
                    </div>                    

                    <button class="cta">Enviar registro de usuario</button>
                    
                </form>    
                <p class="form__success" v-if="showSuccessRegisterUser">
                    Se ha registrado al usuario. Se le ha enviado un email de notificación a su correo electrónico
                </p>            
                
            </div>

        </section>
        
    </main>       
    
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { ref } from "vue";
import API from "@/api"
import router from "@/router"
import store from "@/store"
import HeadShop from "@/views/private/shop/components/HeadShop";

export default {
    name: 'RegisterUser',
    components: {
        HeadShop,
        Loading,
    },
    setup(){
        var isLoading = ref(false)
        const fullPage = true

        const dataShop = ref({
            name: "",
            country: "",
            department: "",
            address: ""
        });
        const formRegisterUser = ref({
            type_user: null,
            first_name: "",
            last_name: "",
            document_code: "",
            email: "",
            username: "demo1",
            shop_that_registered: store.state.dataToken.shop , //tienda que lo registro
        })
        const typeUser = ref([])
        const messageError = ref({errors: {}})
        const showSuccessRegisterUser = ref(Boolean)
        
        showSuccessRegisterUser.value = false

        const getDataShop = () => ({
            
            name: localStorage.getItem('name'),
            country: localStorage.getItem('country'),
            department: localStorage.getItem('department'),
            address: localStorage.getItem('address')
        })
        
        dataShop.value = getDataShop()

        const getTypeUser = async () => {
            
            await API.get("/api/type-user")
                .then(response => {
                    typeUser.value = response.data                    
                    
                }).catch(error => {                    
                    console.log(error.response.data)                    

                })
                
        }

        const onSubmit = () => {
            isLoading.value = true
            API.post('/api/user', formRegisterUser.value)
                .then(response => {
                    isLoading.value = false
                    showSuccessRegisterUser.value = true
                })
                .catch(error => {
                    isLoading.value = false
                    messageError.value = error.response.data
                })
        }

        return { 
            isLoading, 
            fullPage, 
            dataShop, 
            formRegisterUser, 
            typeUser, 
            getTypeUser, 
            onSubmit, 
            messageError, 
            showSuccessRegisterUser 
        }

    },
    mounted(){
        this.getTypeUser();
    }


    
}
</script>